<template>
  <div class="btn-position fs-52">
        <!-- <router-link :to="{name: 'Setup'}" tag="button" class="btn btn-lg btn-primary btn-block btn-maksimal" >{{$l.detectLanguage('backHome')}}</router-link> -->
        <button class="btn btn-lg btn-primary btn-block btn-maksimal" @click="backHome">Reload</button>
        <button class="btn btn-lg btn-primary btn-block btn-maksimal" @click="shutdown">{{$l.detectLanguage('shutDown')}}</button>
        <button class="btn btn-lg btn-primary btn-block btn-maksimal" @click="restart">{{$l.detectLanguage('restart')}}</button>
        <div class="mt-2">
            <label class=" c-primary fw-700 fs-14 text-center">
               <qrcode-vue :value="JSON.stringify(qrData)" :margin="4" style="border-radius: 20px;" :size="300"/>
               {{qrData.deviceId}}
            </label>
        </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import { useSelector } from '@xstate/vue'
export default {
  data () {
    const selectorState = (state) => {
      return state.context.deviceId && state.context.socketId ? {
        deviceId: state.context.deviceId,
        socketId: state.context.socketId
      } : {}
    }
    const context = useSelector(window.fetchMachine, selectorState)
    return {
      qrData: context
    }
  },
  components: {
    QrcodeVue
  },
  methods: {
    ...mapActions({
      shutdown: 'DocumentHandler/SHUTDOWN_KIOSK',
      restart: 'DocumentHandler/RESTART_KIOSK'
    }),
    backHome () {
      location.reload()
    }
  }
}
</script>

<style>
</style>
